import React from "react";

export const Footer = () => {
  return (
    <>
      <footer id="footer">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-md-6">
                <div className="footer-info">
                  <a className="logo text-decoration">
                    <h2 className="content-color-gold ">Khadhan</h2>
                  </a>
                  <p>
                    Plot No. 415, Tilai, Village Dadhiya Tahsil Bahari District
                    Sidhi Sidhi Sidhi MP 486675 IN.
                  </p>
                  <p>
                    <br />
                    <br />
                    <strong>Phone:</strong> +91 9589167394
                    <br />
                    <strong>Email:</strong> info@khadhan.com
                    <br />
                  </p>
                  <div className="social-links mt-3">
                    <a href="https://wa.me/9589167394" className="twitter">
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                    <a href="tel:+919589167394">
                      <i className="bi bi-phone align-items-center"></i>
                    </a>
                    <a href="https://www.facebook.com/profile.php?id=100088496933482">
                      <i className="bi bi-facebook"></i>
                    </a>
                    <a href="https://www.instagram.com/khadhan_/">
                      <i className="bi bi-instagram"></i>
                    </a>
                    <a href="https://wa.me/9589167394" className="twitter">
                      <i className="bx bxl-whatsapp"></i>
                    </a>
                  </div>
                </div>
              </div>

              <div className="logo col-lg-5 col-md-6 footer-links">
                <h4>
                  <a className="logo text-decoration content-color-gold">
                    Useful Links
                  </a>
                </h4>
                <ul>
                  <li>
                    <i className="bx bx-chevron-right"></i> <a href="#">Home</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#about">About us</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>{" "}
                    <a href="#specials">Services</a>
                  </li>
                  <li>
                    <i className="bx bx-chevron-right"></i>
                    <a href="assets/docs/privacyPolicy.html">
                      Policy & Terms of service
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="copyright">
            &copy; Copyright{" "}
            <strong>
              <span className="content-color-gold">Khadhan</span>
            </strong>
            . All Rights Reserved
          </div>
          <div className="credits">
            Developed by{" "}
            <a
              href="https://www.linkedin.com/in/swami-malavade-8447411a6/"
              className="text-decoration content-color-gold"
            >
              Swami Malavade
            </a>
          </div>
        </div>
      </footer>

      <a
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </>
  );
};
