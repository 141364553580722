import React from "react";

export const AboutUs = () => {
  return (
    <div>
      <section id="about" className="about">
        <div className="container pb-5 " data-aos="fade-up">
          <div className="row">
            <div
              className="col-lg-6 order-1 order-lg-2"
              data-aos="zoom-in"
              data-aos-delay="100"
              style={{ marginTop: "50px" }}
            >
              <div className="about-img">
                <img src="assets/img/about.jpg" alt="" />
              </div>
            </div>
            <div
              className="col-lg-6 pt-4 pt-lg-0 pb-5 order-2 order-lg-1 content content-color-white page-margin"
              style={{ marginTop: "50px" }}
            >
              <h3>About Us</h3>
              <p>
                Khadhan has built itself over 5 years, as a trusted supplier of
                mining items in Madhya Pradesh, as well as key services for
                mining facilities that ensure the safety and reliability of
                items used. Khadhan is a new-age 'Mining item supply’ firm that
                empowers businesses to be fast and fail-proof through
                state-of-the-art mining item solutions. We are here to deal with
                the delivery of mining material as per customer requirements. We
                will provide a designated service only for the delivery of
                mining material.
              </p>
              <p>
                Khadhan is committed to transforming mining material in India
                through investments in made-in-India technology innovations,
                customer-centric constructs, a diverse category landscape, and a
                world-class supply chain. We work closely with our clients to
                bring them solutions that fit the needs of their facilities,
                whether they require delivery of mining items, transportation
                services, and more.
              </p>
              <p>
                Our services include delivery for mining items, and offering
                service and maintenance tasks when needed for mining items. In
                addition, we also provide transportation services which will
                help you to deliver your product very gently.
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};
