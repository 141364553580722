import React, { useEffect, useState } from "react";

export const Header = () => {
  const [isActive, setIsActive] = useState({
    home: true,
    about: false,
    products: false,
    projects: false,
    founders: false,
    gallery: false,
    contactUs: false,
  });

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  useEffect(() => {
    window.addEventListener("scroll", changeNavColor);
  });

  const changeNavColor = () => {
    let scrollValue = window.scrollY;
    // console.log("8888888888888888888",scrollValue);
    if (scrollValue > 0 && scrollValue < 595) {
      setIsActive({
        home: true,
        about: false,
        products: false,
        projects: false,
        founders: false,
        gallery: false,
        contactUs: false,
      });
    }
    if (scrollValue > 600 && scrollValue < 1400) {
      setIsActive({
        home: false,
        about: true,
        products: false,
        projects: false,
        founders: false,
        gallery: false,
        contactUs: false,
      });
    }
    if (scrollValue > 2000 && scrollValue < 2760) {
      setIsActive({
        products: true,
      });
    } else {
      setIsActive({
        home: false,
        about: false,
        products: false,
        projects: false,
        founders: false,
        gallery: false,
        contactUs: false,
      });
    }
    if (scrollValue > 4100) {
      setIsActive({
        projects: true,
      });
    }
    if (scrollValue > 4890) {
      setIsActive({
        founders: true,
      });
    }
    if (scrollValue > 5550) {
      setIsActive({
        events: true,
      });
    }
    if (scrollValue > 6350) {
      setIsActive({
        gallery: true,
      });
    }
    if (scrollValue > 8580) {
      setIsActive({
        contactUs: true,
      });
    }
  };

  const handleClick = (e) => {
    const obj = {
      home: false,
      about: false,
      products: false,
      projects: false,
      founders: false,
      events: false,
      gallery: false,
    };
    const temp = { ...obj, [e.target.name]: true };
    setIsActive(() => {
      return temp;
    });
  };

  const isSticky = (e) => {
    const header = document.querySelector("#header");
    const scrollTop = window.scrollY;

    scrollTop >= 250
      ? header.classList.add("header-scrolled")
      : header.classList.remove("header-scrolled");
  };

  return (
    <header id="header" className="fixed-top d-flex align-items-center">
    <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
      <a
        href="index.html"
        className="logo me-auto me-lg-0 content-color-gold text-decoration"
      >
        <img
          src="assets/img/logo.png"
          alt="Image Not Found"
          className="img-fluid"
          style={{ width: "50px", height: "100px" }}
        />{" "}
        Khadhan
      </a>
      <nav id="navbar" className="navbar order-last order-lg-0">
        <ul>
          <li>
            <a
              className="nav-link scrollto"
              href="#hero"
              name="home"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.home ? "#cda45e" : "white"}` }}
            >
              Home
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#about"
              name="about"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.about ? "#cda45e" : "white"}` }}
            >
              About
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#menu"
              name="products"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.products ? "#cda45e" : "white"}` }}
            >
              Products
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              name="projects"
              href="#specials"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.projects ? "#cda45e" : "white"}` }}
            >
              Projects
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              name="founders"
              href="#chefs"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.founders ? "#cda45e" : "white"}` }}
            >
              Founders
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#events"
              name="events"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.events ? "#cda45e" : "white"}` }}
            >
              Events
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#gallery"
              name="gallery"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.gallery ? "#cda45e" : "white"}` }}
            >
              Gallery
            </a>
          </li>
          <li>
            <a
              className="nav-link scrollto"
              href="#contactus"
              name="contactUs"
              onClick={(e) => handleClick(e)}
              style={{ color: `${isActive.contactUs ? "#cda45e" : "white"}` }}
            >
              Contact Us
            </a>
          </li>
        </ul>
        <i className="bi bi-list mobile-nav-toggle"></i>
      </nav>
      <a
        href="#book-a-table"
        className="book-a-table-btn scrollto d-none d-lg-flex text-decoration"
      >
        Enquiry
      </a>
    </div>
    </header>
  );
};

// Latest code below


{
  /* <header id="header" className="fixed-top d-flex align-items-center">
      <div className="container-fluid container-xl d-flex align-items-center justify-content-lg-between">
        <a
          href="index.html"
          className="logo me-auto me-lg-0 content-color-gold text-decoration"
        >
          <img
            src="assets/img/logo.png"
            alt="Image Not Found"
            className="img-fluid"
            style={{ width: "50px", height: "100px" }}
          />{" "}
          Khadhan
        </a>
        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>
              <a
                className="nav-link scrollto"
                href="#hero"
                name="home"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.home ? "#cda45e" : "white"}` }}
              >
                Home
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#about"
                name="about"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.about ? "#cda45e" : "white"}` }}
              >
                About
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#menu"
                name="products"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.products ? "#cda45e" : "white"}` }}
              >
                Products
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                name="projects"
                href="#specials"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.projects ? "#cda45e" : "white"}` }}
              >
                Projects
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                name="founders"
                href="#chefs"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.founders ? "#cda45e" : "white"}` }}
              >
                Founders
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#events"
                name="events"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.events ? "#cda45e" : "white"}` }}
              >
                Events
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#gallery"
                name="gallery"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.gallery ? "#cda45e" : "white"}` }}
              >
                Gallery
              </a>
            </li>
            <li>
              <a
                className="nav-link scrollto"
                href="#contactus"
                name="contactUs"
                onClick={(e) => handleClick(e)}
                style={{ color: `${isActive.contactUs ? "#cda45e" : "white"}` }}
              >
                Contact Us
              </a>
            </li>
          </ul>
          <i className="bi bi-list mobile-nav-toggle"></i>
        </nav>
        <a
          href="#book-a-table"
          className="book-a-table-btn scrollto d-none d-lg-flex text-decoration"
        >
          Enquiry
        </a>
      </div>
    </header> */
}
