import React, { useEffect } from "react";

export const Arrow = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector("#arrow");
    const scrollTop = window.scrollY;

    scrollTop >= 250
      ? header.classList.add("arrow-scrolled")
      : header.classList.remove("arrow-scrolled");
  };

  return (
    <div>
      <a
        id="arrow"
        href="#"
        className="back-to-top d-flex align-items-center justify-content-center active"
      >
        <i className="bi bi-arrow-up-short"></i>
      </a>
    </div>
  );
};
