import { HomePage } from "./components/home/HomePage.jsx";
import { AboutUs } from "./components/about_us/AboutUs.jsx";
import { WhyUs } from "./components/why_us/WhyUs.jsx";
import { Testimonials } from "./components/testimonials/Testimonials.jsx";
import { Products } from "./components/products/Products.jsx";
import { Founders } from "./components/founders/Founders.jsx";
import { Projects } from "./components/project/Projects";
import Enquiry from "./components/enquiry/Enquiry.js";
import { Gallery } from "./components/gallery/Gallery";
import { Header } from "./components/home/header";
import { Topbar } from "./components/home/topbar";
import { Footer } from "./components/footer/Footer.jsx";
import { Arrow } from "./components/arrow/Arrow.jsx";
import ContactUs from "./components/ContactUs/ContactUs.jsx";
import Events from "./components/Events/Events.jsx";

function App() {
  return (
    <>
      <Topbar />
      <Header />
      <HomePage />
      <AboutUs />
      <WhyUs />
      <Products />
      <Enquiry />
      <Testimonials />
      <Projects />
      <Founders />
      <Events />
      <Gallery />
      <ContactUs />
      <Arrow />
      <Footer />
    </>
  );
}

export default App;
