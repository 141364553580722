import React, { useState } from "react";
import "./Projects.css";

const dataSet = [
  {
    id: "1",
    title: "Gaurav Stone Crusher",
    description:
      "Gaurav Stone crusher are extensively used to crush bulk and heavy stones into different sizes efficiently and reliably. We divide all the rocks mainly into two groups. We use Compressive crushers that press the material until it breaks, and impact crushers use the principle of quick impacts to crush the material. Gaurav Stone crusher plays an important role in the industrial sector in the country engaged in producing crushed stone of various sizes depending upon the requirement which acts as raw material for various construction activities such as the construction of Roads, Highways, Bridges, Buildings, Canals, etc.",
    address:
      "PLOT NO. 415, TILAI, VILLAGE DADHIYA TAHSIL BAHARI DISTRICT SIDHI SIDHI Sidhi MP 486675 IN.",
    location: "https://goo.gl/maps/zfJAGLnVkTNPNQCy6",
    image: "assets/img/projects/gaurav-stone-crusher.jpg",
  },
  {
    id: "2",
    title: "Faith Farming",
    description:
      "Suraj Stone crushers are also part of Gaurav Stone crusher that are extensively used to crush bulk and heavy stones into different sizes efficiently and depending upon the requirement they act as raw material for various construction activities such as the construction of Roads, Highways, and Bridges, Buildings, Canals, etc. We divide all the rocks mainly into two groups. We use Compressive crushers that press the material until it breaks, and impact crushers use the principle of quick impacts to crush the material.",
    address: "Jamodi thana Sidhi MP 486661.",
    location: "https://goo.gl/maps/zfJAGLnVkTNPNQCy6",
    image: "assets/img/projects/faith-farming.jpg",
  },
  {
    id: "3",
    title: "MB Mines & Minerals Private Limited",
    description:
      "MB Mines & Minerals Private Limited is involved in the extraction of precious minerals and other geological materials. The extracted materials are transformed into a mineralized form that serves an economic benefit to the prospector or miner. We are here to deal with Major minerals like coal, Iron and platinum, etc. Our aim is India’s largest iron ore producer which will demonstrate remarkable consistency to achieve operational excellence and meet the iron ore needs of the country. We are also planning to involve in the mining of copper, iron ore, and aluminum.",
    address:
      "Bahari Village, Sihawal Tehsil, Sidhi District, Sidhi, Madhya Pradesh 486661.",
    location: "https://goo.gl/maps/zfJAGLnVkTNPNQCy6",
    image: "assets/img/projects/mb-mines-pltd.jpg",
  },
  {
    id: "4",
    title: "Suraj Stone Crusher",
    description:
      "Suraj Stone crushers are also part of Gaurav Stone crusher that are extensively used to crush bulk and heavy stones into different sizes efficiently and depending upon the requirement they act as raw material for various construction activities such as the construction of Roads, Highways, and Bridges, Buildings, Canals, etc. We divide all the rocks mainly into two groups. We use Compressive crushers that press the material until it breaks, and impact crushers use the principle of quick impacts to crush the material.",
    address: "Jamodi thana Sidhi MP 486661.",
    location: "https://goo.gl/maps/rWUCUPbnHU148yW6A",
    image: "assets/img/projects/suraj-stone-crusher.jpg",
  },
];

export const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(dataSet[0].title);

  const renderProjectDetails = () => {
    const selectedProjectData = dataSet.find((project) => {
      return project.title === selectedProject;
    });

    if (!selectedProjectData) {
      return <></>
    }

    return (
      <div className="tab-pane active show" key={selectedProjectData.id}>
        <div className="row">
          <div>
            <div
              className="col-lg-8 details order-2 order-lg-1 d-flex media-display"
              style={{ width: "100%" }}
            >
              <div style={{ width: "90%" }} className="me-3">
                <h3>{selectedProjectData.title}</h3>
                <p>{selectedProjectData.description}</p>
                <a
                  className="content-color-gold"
                  target="_blank"
                  href={selectedProjectData.location}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    className="bi bi-pin-map"
                    viewBox="0 0 16 16"
                  >
                    <path
                      fillRule="evenodd"
                      d="M3.1 11.2a.5.5 0 0 1 .4-.2H6a.5.5 0 0 1 0 1H3.75L1.5 15h13l-2.25-3H10a.5.5 0 0 1 0-1h2.5a.5.5 0 0 1 .4.2l3 4a.5.5 0 0 1-.4.8H.5a.5.5 0 0 1-.4-.8l3-4z"
                    />
                    <path
                      fillRule="evenodd"
                      d="M8 1a3 3 0 1 0 0 6 3 3 0 0 0 0-6zM4 4a4 4 0 1 1 4.5 3.969V13.5a.5.5 0 0 1-1 0V7.97A4 4 0 0 1 4 3.999z"
                    />
                  </svg>
                  <i> {selectedProjectData.address}</i>
                </a>
              </div>
              <div>
                <img
                  src={selectedProjectData.image}
                  alt="add image here"
                  className="img-fluid"
                />
              </div>
            </div>
            <br />
          </div>
        </div>
      </div>
    );
  };

  return (
    <section id="specials" className="specials background-color-black">
      <div className="container" data-aos="fade-up">
        <div className="section-title" style={{ marginTop: "50px" }}>
          <h2>Projects</h2>
          <p>Our Projects</p>
        </div>

        <div className="row " data-aos="fade-up" data-aos-delay="100">
          <div className="col-lg-3">
            <ul className="nav nav-tabs flex-column">
              {dataSet.map((data) => {
                return (
                  <li
                    className="nav-item"
                    key={data.id}
                    onClick={() => setSelectedProject(data.title)}
                  >
                    <a
                      className={`nav-link show ${
                        data.title === selectedProject && "active "
                      }`}
                      data-bs-toggle="tab"
                      href={`#${data.id}`}
                    >
                      {data.title}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>

          <div className="col-lg-9 mt-4 mt-lg-0 ">
            <div className="tab-content ">{renderProjectDetails()}</div>
          </div>
        </div>
      </div>
    </section>
  );
};
