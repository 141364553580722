import axios from "axios";
import React, { useEffect, useState } from "react";

function Enquiry() {
  const [isAlert, setIsAlert] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [isError, setIsError] = useState(false);
  const [obj, setObj] = useState({
    customerName: "",
    customerMobile: "",
    query: "",
  });
  const handleClick = (e) => {
    e.preventDefault();
    setIsSubmit(true);
    if (Object.values(obj)?.every((key) => key != "")) {
      setIsAlert(true);
      setIsError(false);
      axios
        .post(
          "https://lbjdhtrff0.execute-api.ap-south-1.amazonaws.com/Prod/support/query",
          obj
        )
        .then((resp) => {
          console.log(resp);
          setObj({customerName: "",
          customerMobile: "",
          query: "",})
          setEmail("")
        })
        .catch((error) => {
          console.log(error);
        });
    } else {
      setIsAlert(false);
      setIsError(true);
    }
  };

  const handleChange = (e) => {
    let temp = { ...obj, [e.target.name]: e.target.value };
    setObj(temp);
  };

  return (
    <section id="book-a-table" className="book-a-table background-color-black">
      <div className="container pb-5" data-aos="fade-up">
        <div className="section-title pb-5" style={{ marginTop: "50px" }}>
          <h2>Enquire</h2>
        </div>

        {/* {isError && (
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="alert alert-dark mt-3" role="alert">
              Please fill all the details!
            </div>
            <div></div>
          </div>
        )} */}
        {/* Object.values(obj)?.every((key) => key != "") &&  */}
        {isAlert ? (
          <div className="d-flex justify-content-between">
            <div></div>
            <div className="alert alert-dark mt-3" role="alert">
              Your Request Has Been Submitted! We'll Get Back To You Shortly
            </div>
            <div></div>
          </div>
        ) : (
          ""
        )}
        <form
          className="php-email-form"
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <div className="row">
            <div className="col-lg-4 col-md-6 form-group">
              <input
                type="text"
                name="customerName"
                className="form-control"
                id="name"
                value={obj?.customerName}
                placeholder="Your Name"
                data-rule="minlen:4"
                data-msg="Please enter at least 4 chars"
                onChange={handleChange}
              />
              {isError && obj.customerName <= 0 ? (
                <label className="text-danger">
                  Please Enter Your First Name
                </label>
              ) : (
                ""
              )}
              <div className="validate"></div>
            </div>
            <div className="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
              <input
                type="email"
                className="form-control"
                name="customerEmail"
                id="email"
                value={email}
                placeholder="Your Email"
                data-rule="email"
                data-msg="Please enter a valid email"
                onChange={(e) => setEmail(e.target.value)}
              />
              {/* {isError && obj.customerEmail <= 0 ? (
                <label className="text-danger">Please Enter Your Email</label>
              ) : (
                ""
              )} */}
              <div className="validate"></div>
            </div>
            <div className="col-lg-4 col-md-6 form-group mt-3 mt-md-0">
              <input
                type="text"
                className="form-control"
                name="customerMobile"
                id="phone"
                value={obj?.customerMobile}
                placeholder="Your Phone"
                data-rule="minlen:4"
                data-msg="Please enter at least 4 chars"
                onChange={handleChange}
              />
              {isError && obj.customerMobile <= 0 ? (
                <label className="text-danger">
                  Please Enter Your Phone Number
                </label>
              ) : (
                ""
              )}
              <div className="validate"></div>
            </div>
          </div>
          <div className="form-group mt-3">
            <textarea
              className="form-control"
              name="query"
              value={obj?.query}
              rows="5"
              placeholder="Message"
              onChange={handleChange}
            ></textarea>
            {isError && obj.query <= 0 ? (
              <label className="text-danger">
                Please let us know what you looking for
              </label>
            ) : (
              ""
            )}
            <label className="text-danger"></label>
            <div className="validate"></div>
          </div>
          <div className="mb-3">
            <div className="loading">Loading</div>
            <div className="error-message"></div>
            <div className="sent-message">
              Your booking request was sent. We will call back or send an Email
              to confirm your reservation. Thank you!
            </div>
          </div>
          <div className="text-center pb-5 mb-4">
            <button className="mb-5" type="submit" onClick={handleClick}>
              Submit
            </button>
          </div>
        </form>
      </div>
    </section>
  );
}

export default Enquiry;
