import React from "react";
import "./Gallery.css";

export const Gallery = () => {
  const dataSet = [
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/1472060525-origin.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/1593441437_29dhan-mp-support-commercial-mining-4-jpg.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/DSC_3441.JPG",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/DSC_3453.JPG",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/DSC_3467.JPG",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/DSC_3470.JPG",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/DSC_3479.JPG",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/DSC_3482.JPG",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/coal-mining-og.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/coal.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/honey-blue-granite-slab-500x500.webp",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/industry-2023592_1920.webp",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/quarry-7703096_1920.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/maxresdefault.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/mining-440743_1280.jpg",
    "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Image%20for%20Gallery%20/excavator-3282111_1920.jpg",
  ];
  return (
    <section id="gallery" className="gallery background-color-black">
      <div className="container" data-aos="fade-up">
        <div className="section-title" style={{ marginTop: "50px" }}>
          <h2>Gallery</h2>
          <p>Some photos from Our Minning Houses</p>
        </div>
      </div>

      <div className="container-fluid" data-aos="fade-up" data-aos-delay="100">
        <div className="row g-2">
          {dataSet.map((data) => {
            return (
              <div className="col-xl-3 col-lg-4 col-md-6" key={data}>
                <div className="gallery-item">
                  <a href={data} className="gallery-lightbox">
                    <img src={data} alt="" className="img-fluid" />
                  </a>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
