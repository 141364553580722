import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

const dataSet = [
  {
    name: "- Gaurav Singh Baghel",
    designation: "(Director)",
    review:
      "Our aim is to create a network between transporter, producer, and customers as well as supply mining material; in this, we have an excellent success rate in meeting or exceeding our customer’s expectations.",
  },
  {
    name: "- Suraj Singh",
    designation: "(Producer)",
    review:
      "Khadhan is always available for on-site technical support, ensuring Khadhan is available through every phase from the order place to the delivery of the product. Khadhan also provides a very good network between us (producer) & transporter. We can easily show our product availability on the app accordingly the transporter visits our production house.",
  },
  {
    name: "- Abhijeet Singh",
    designation: "(Customer)",
    review:
      "The best part which I liked most about Khadhan is that u simply stay anywhere and your product from any good production house or any transporters is just a click away. You can see the live status of your order starting from the production houses or any transporter's location has accepted the order or not, did the transporter moved from their location or not, means each and everything.",
  },
  {
    name: "- Shubham Pratap Singh Baghel",
    designation: "(Transporter)",
    review:
      "It is the best application I am using for the last two months for tracking my vehicle and accepting orders from customers. I am totally satisfied with the services of the Khadhan application. This application also helps me to know the availability of mining products in production houses. It is very easy to use this application.",
  },
  {
    name: "- Kuldeep Singh Baghel",
    designation: "(Transporter)",
    review:
      "Most of the time I stay out of my hometown and I do not have to worry about my vehicle because Khadhan takes care of that. I can track my vehicle using Khadhan during the entire trip whenever I am unable to connect with my driver.",
  },
  {
    name: "- Sawan Kaulic ",
    designation: "(Customer)",
    review:
      "Khadhan has made our lives easier by allowing us to explore mining products that are in town. Being a busy person in my daily routine I have used Khadhan app to try out the consultation services for bricks & river sand. I usually filter out the transporter & producer that have 4-star ratings and good reviews so that I can take services from them.",
  },
  {
    name: "- Abhay Singh",
    designation: "(Producer)",
    review:
      "Khadhan`s team have substantial product knowledge regarding a range of service and products, ensuring that Khadhan is able to identify any potential bottlenecks in your system and provide efficient solutions.",
  },
  {
    name: "- Shiwam Shinha",
    designation: "(Customer)",
    review:
      "I have been very happy with the service provided by Khadhan. Khadhan support team has been wonderful! They have always returned my calls very quickly, and they answered all my questions.",
  },
  {
    name: "- Vishal Khatri",
    designation: "(Customer)",
    review:
      "I like everything about Khadhan. The thing that is best about Khadhan is it delivers my mining items so fast and every delivery partner(transporter) is so humble. It offers so many services apart from online mining items supply like consultation services for mining items & transport services.",
  },
  {
    name: "- Kuldeep Mishra",
    designation: "(Transporter)",
    review:
      "It's a great mining item delivery application that covers a huge number of mining products as well as transporters and producers. The pro feature of Khadhan application is worth mentioning as it provides a very good service.",
  },
];

export const Testimonials = () => {
  return (
    <section id="testimonials" className="testimonials section-bg">
      <div className="container aos-init aos-animate" data-aos="fade-up">
        <div className="section-title">
          <h2>Testimonials</h2>
          <p>What they're saying about us</p>
        </div>

        <div
          className="testimonials-slider aos-init aos-animate" //swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events
          data-aos="fade-up"
          data-aos-delay="100"
        >
          <Swiper
            modules={[Pagination, Autoplay]}
            spaceBetween={30}
            slidesPerView={3}
            autoplay={true}
            pagination={{ clickable: true }}
            className="mySwiper"
            breakpoints={{
              // when window width is >= 320px
              320: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              // when window width is >= 640px
              640: {
                slidesPerView: 2,
                spaceBetween: 40,
              },
              // when window width is >= 768px
              800: {
                slidesPerView: 3,
                spaceBetween: 40,
              },
            }}
          >
            {dataSet.map((data) => {
              return (
                <SwiperSlide key={data.name}>
                  <div className="testimonial-item">
                    <p style={{ color: "white" }}>
                      <i className="bx bxs-quote-alt-left quote-icon-left"></i>
                      {data.review}
                      <i className="bx bxs-quote-alt-right quote-icon-right"></i>
                    </p>
                    <h3>{data.name}</h3>
                    <h4>{data.designation}</h4>
                  </div>
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    </section>
  );
};
