import React, { useEffect, useState } from "react";
import mobileApp from "../../Images/Mobile App.png";
import "./Products.css";

const products = [
  {
    type: "Sand",
    values: [
      {
        title: "River Sand",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Sand/1%28a%29sand_Subtype_riverSand.jpg",
      },
      {
        title: "Manufacture Sand",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Sand/1%28b%29sand_subtype_manufactured.jpg",
      },
    ],
  },
  {
    type: "Concrete",
    values: [
      {
        title: "W mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28a%29-concrete_subtype_wmm.JPG",
      },
      {
        title: "65 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28b%29concrete_subtype_65mm.JPG",
      },
      {
        title: "40 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28c%29concrete_subtype_40mm.JPG",
      },
      {
        title: "20 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28d%29concrete_subtype_20mm.JPG",
      },
      {
        title: "18 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28e%29concrete_subtype_18mm.JPG",
      },
      {
        title: "12 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/12mm-crushed-stone-1642159208-6158466.jpeg",
      },
      {
        title: "10 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28g%29concrete_subtype_10mm.JPG",
      },
      {
        title: "06 mm",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28f%29concrete_subtype_6mm.JPG",
      },
      {
        title: "Dust",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Concrete/2%28h%29concrete_subtype_dust_mm.JPG",
      },
    ],
  },
  {
    type: "Bricks",
    values: [
      {
        title: "Clay Bricks",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Bricks/burnt-red-clay-bricks-838.jpg",
      },
      {
        title: "Concrete Bricks",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Bricks/3%28d%29concrete_bricks.jpg",
      },
    ],
  },
  {
    type: "Clay",
    values: [
      {
        title: "Murum",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/clay/4%28a%29clay_murum.webp",
      },
      {
        title: "Normal Clay",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/clay/4%28b%29clay_normalClay.jpg",
      },
    ],
  },
  {
    type: "Granite",
    values: [
      {
        title: "Black Granite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Granite/5%28a%29granite_subtype_black.jpg",
      },
      {
        title: "Brown Granite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Granite/5%28c%29granite_subtype_brown.jpg",
      },
      {
        title: "Gold Granite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Granite/5%28d%29granite_subtype_gold.jpg",
      },
      {
        title: "White Granite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Granite/5%28e%29granite_subtype_white.jpg",
      },
      {
        title: "Red Granite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Granite/5%28b%29granite_subtype_red.jpg",
      },
    ],
  },
  {
    type: "Marble",
    values: [
      {
        title: "White Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28a%29White_Marble.jpg",
      },
      {
        title: "Black Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28b%29Black_marble.jpg",
      },
      {
        title: "Brown Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28c%29Brown_Marble.jpg",
      },
      {
        title: "Cream Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28d%29Cream_Marble.jpg",
      },
      {
        title: "Grey Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28e%29Grey_Marble.jpg",
      },
      {
        title: "Yellow Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28f%29Yellow_Marble.jpg",
      },
      {
        title: "Red Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28g%29Red_Marble.jpg",
      },
      {
        title: "Beige Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28h%29Beige_Marble.jpg",
      },
      {
        title: "Green Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28I%29Green_Marble.jpg",
      },
      {
        title: "Pink Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28j%29Pink_Marble.jpg",
      },
      {
        title: "Silver Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28k%29Silver_Marble.jpg",
      },
      {
        title: "Ivory Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28l%29Ivory_Marble.jpg",
      },
      {
        title: "Blue Marble",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Marble/6_%28m%29Blue_marble.jpg",
      },
    ],
  },
  {
    type: "Coal",
    values: [
      {
        title: "Anthracite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Coal/7_%28f%29coal_anthracite.jpg ",
      },
      {
        title: "Bituminous Coal",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Coal/7_%28b%29coal_subType_sub-bituminous-coal.jpeg",
      },
      {
        title: "Sub-Bituminous Coal",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Coal/7_%28c%29coal_subtype_Sub-Bituminous%20coal.webp",
      },
      {
        title: "Lignite",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Coal/7_%28d%29coal_subtype_sub-lignite.jpg",
      },
      {
        title: "Peat",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Coal/7_%28e%29coal_subtype_Peat.jpg",
      },
    ],
  },
  {
    type: "Kapda",
    values: [
      {
        title: "Factory Made",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Khapda/8_khapda_front_photo.webp",
      },
      {
        title: "Local Made",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Khapda/8_%28b%29khapda_subtype_local%20made.JPG",
      },
    ],
  },
  {
    type: "Patiya",
    values: [
      {
        title: "Factory Made",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Patiya/9_%28a%29patiya_factory%20made.webp",
      },
      {
        title: "Local Made",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Patiya/9-%28b%29patiya_local_made.webp",
      },
    ],
  },
  {
    type: "Chuhi",
    values: [
      {
        title: "No Specific Type",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Chuhi/chuhi.avif",
      },
    ],
  },
  {
    type: "Chuna",
    values: [
      {
        title: "Dry-Slaked Lime",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Chuna/11_%28a%29chuna_subtype_Dry-slaked%20Lime.webp",
      },
      {
        title: "Lime Putty",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Chuna/11_%28c%29chuna_subtype_lime.webp",
      },
      {
        title: "Non-Hydraluic Lime",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Chuna/11_%28d%29chuna_subtype_non_hydrated.png",
      },
      {
        title: "Hydraluic Lime",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Chuna/11_%28b%29chuna_subtype_hydrated-lime.webp",
      },
    ],
  },
  {
    type: "Dhoka/ Bolder",
    values: [
      {
        title: "No Specific Type",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Dhokha/12-dhoka_bolder_front_image.JPG",
      },
    ],
  },
  {
    type: "Hand Broken Gitti",
    values: [
      {
        title: "Mix material (0 to 40mm) (mix material for highways)",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Handbroken%20gitti/13_%28b%29hand_broken_mixMatrial.JPG",
      },
      {
        title: "Mix material (0 to 70mm) (mix material for local roads)",
        image:
          "https://khadhan-assets.s3.ap-south-1.amazonaws.com/images_websites/Handbroken%20gitti/13_%28a%29hand_broken_mixMaterial_localRoadways.JPG",
      },
    ],
  },
];

export const Products = () => {
  const [selectedProduct, setSelectedProduct] = useState(products[0].type);
  const [isActive, setIsActive] = useState({ sand: true });

  useEffect(()=>{
    setSelectedProduct("Sand")
  },[])

  const renderProducts = () => {
    const selectedProductData = products.find((product) => {
      return product.type === selectedProduct;
    });
    const valuesToDisplay = selectedProductData?.values;

    return valuesToDisplay.map((value) => {
      return (
        <div className={`col-lg-6 menu-item `} key={value.image}>
          <img
            src={value.image}
            className="menu-img my-2"
            alt={value.title}
            style={{ width: "140px", height: "140px" }}
          />
          <div className="menu-content my-5">
            <a className="mx-3 text-decoration"> {value.title}</a>
          </div>
        </div>
      );
    });
  };

  return (
    <section
      id="menu"
      className="menu section-bg"
      style={{
        display: "grid",
        gridAutoRows: "minmax(100px, max-content)",
        minHeight: "700px",
      }}
    >
      <div className="container" data-aos="fade-up">
        <div className="section-title" style={{ marginTop: "50px" }}>
          <h2>Products</h2>
          <p>What We Offer/ Our Products</p>
        </div>
        <div
          id="products-title"
          data-aos="fade-up"
          data-aos-delay="100"
          className="d-flex"
        >
          {products.map((product) => {
            return (
              <div
                className="justify-content-center align-self-center text-light"
                key={product.type}
                id="menu-flters"
                style={{
                  cursor:"pointer"
                }}
                onClick={() => {
                  setSelectedProduct(product.type);
                }}
              >
                {product.type}
                {/* <ul id="menu-flters">
                  <li
                    onClick={() => {
                      setSelectedProduct(product.type);
                    }}
                  >
                    {product.type}
                  </li>
                </ul> */}
              </div>
            );
          })}
        </div>
        <br />
        <div
          className="row menu-container set-height"
          style={{ minHeight: "200px" }}
          data-aos="fade-up"
          data-aos-delay="200"
        >
          {renderProducts()}
        </div>

        <div className="col-12 rounded-3 online-ordering-box ">
          <div className="text-light fw-bold m-3">
            Online ordering is supported on Mobile App
            <button
              className=" border bg-light fw-bold mt-2 rounded-4 p-2 "
              style={{ fontSize: "15px" }}
            >
              Download the App
            </button>
          </div>
          <div className="pt-3" style={{ width: "90%" }}>
            <img
              src={mobileApp}
              alt="Image Not Found"
              style={{ width: "100px", height: "140px" }}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
