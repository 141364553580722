import React, { useEffect } from "react";

export const Topbar = () => {
  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });

  const isSticky = (e) => {
    const header = document.querySelector("#topbar");
    const scrollTop = window.scrollY;

    scrollTop >= 250
      ? header.classList.add("topbar-scrolled")
      : header.classList.remove("topbar-scrolled");
  };

  return (
    <div id="topbar" className="d-flex align-items-center fixed-top">
      <div className="container d-flex justify-content-center justify-content-md-between">
        <div className="contact-info d-flex align-items-center">
          <i className="bi bi-clock d-flex align-items-center ms-4">
            <span> Mon-Sun: Open 24 Hours</span>
          </i>
        </div>

        <div className="languages d-none d-md-flex align-items-center">
          <div className="contact-info d-flex align-items-center">
            <a
              href="tel:+919890905283"
              className="bi bi-phone d-flex align-items-center content-color-gold text-decoration"
            ></a>
            <span style={{ color: "white" }}> +91 9589167394/ </span>
            <a
              href="tel:+919589167394"
              className="bi bi-phone d-flex align-items-center content-color-gold text-decoration"
            ></a>
            <span style={{ color: "white" }}> +91 9340417842</span>
          </div>
        </div>
      </div>
    </div>
  );
};
