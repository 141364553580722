import React from "react";

export const HomePage = () => {
  return (
    <div>
      <section id="hero" className="d-flex align-items-center">
        <div
          className="container position-relative text-center text-lg-start"
          data-aos="zoom-in"
          data-aos-delay="100"
        >
          <div className="row">
            <div className="col-lg-8">
              <h1>
                Welcome to <span style={{ color: "#cda45e" }}>Khadhan</span>
              </h1>
              <h2>Your Vision is Our Mission!</h2>
              <div className="btns">
                <a
                  href="#menu"
                  className="btn-menu animated fadeInUp scrollto text-decoration"
                >
                  Our Products
                </a>
                <a
                  href="#book-a-table"
                  className="btn-book animated fadeInUp scrollto text-decoration"
                >
                  Enquiry
                </a>
              </div>
            </div>
            {/* <div
              className="col-lg-4 d-flex align-items-center justify-content-center position-relative aos-init aos-animate "
              data-aos="zoom-in"
              data-aos-delay="200"
            >
              <a className="glightbox play-btn" href="assets/vid/tp.mp4"></a>
            </div> */}
          </div>
        </div>
      </section>
    </div>
  );
};
