import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay } from "swiper";

const Events = () => {
  return (
    <section id="events" className="events">
      <div className="container" data-aos="fade-up">
        <div className="section-title pt-5">
          <h2>Events</h2>
          <p>Organize Your Events with our Services</p>
        </div>

        <div
          className="testimonials-slider aos-init aos-animate" //swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events
          data-aos="fade-up"
          data-aos-delay="100"
          // id="carouselExampleSlidesOnly"
          // className="carousel slide"
          // data-bs-ride="carousel"
        >
          <Swiper
            modules={[Pagination, Autoplay]}
            // spaceBetween={30}
            slidesPerView={1}
            autoplay={true}
            pagination={{ clickable: true }}
            className="mySwiper"
          >
            <SwiperSlide>
              <div className="carousel-item active">
                <div className="row event-item">
                  <div className="col-lg-6">
                    <img
                      src="assets/img/events/ConvenientMiningItemDelivery.jpg"
                      className="d-block w-100"
                      alt="..."
                    />
                  </div>
                  <div className="col-lg-6 pt-4 pt-lg-0 content">
                    <h3>
                      Convenient Mining Item Delivery: Bringing the Resources to
                      Your Doorstep
                    </h3>
                    <p>
                      At our company, we understand the importance of timely
                      access to mining items and equipment for your operations.
                      That's why we offer a reliable and efficient service that
                      brings the necessary mining items right to your doorstep.
                    </p>
                    <p>
                      With our dedication to customer satisfaction, we aim to
                      make your procurement process as seamless as possible We
                      have an extensive inventory of mining items, encompassing
                      everything from safety equipment to heavy machinery. You
                      can find all the essential resources required for your
                      mining operations in one place.
                    </p>
                    <p>
                      We prioritize efficiency and punctuality in our delivery
                      process. Our dedicated logistics team ensures that your
                      ordered items are promptly packaged, dispatched, and
                      transported to your designated location.
                    </p>
                  </div>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src="assets/img/events/driverTracking.jpg"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>
                    Always-On Driver Tracking: Empowering Transporters with 24/7
                    Visibility
                  </h3>
                  <p>
                    At our company, we understand the crucial role of
                    transporters in ensuring smooth logistics operations. To
                    further empower transporters and optimize their efficiency,
                    we offer a comprehensive driver tracking system that
                    operates 24/7.
                  </p>
                  <p>
                    This cutting-edge feature provides transporters with
                    continuous visibility and control over their drivers'
                    whereabouts. Our driver tracking system seamlessly
                    integrates with our robust logistics management platform,
                    accessible through a user-friendly web portal or a dedicated
                    mobile application.
                  </p>
                  <p>
                    Transporters can conveniently access the system at any time,
                    from anywhere with an internet connection. To further
                    enhance driver tracking and monitoring, our system offers
                    geofencing capabilities. Transporters can set up virtual
                    boundaries and predefined zones on the map, and they will be
                    immediately notified if a driver enters or exits these
                    designated areas. This feature is particularly useful for
                    ensuring drivers stay on track and adhere to planned routes.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src="assets/img/events/ExpertMiningConsultants.webp"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>
                    Expert Mining Consultants: Unlocking Success through
                    Specialized Guidance
                  </h3>
                  <p>
                    Our team of mining consultants comprises industry experts
                    and professionals who are dedicated to providing
                    comprehensive guidance and expertise to mining companies. We
                    understand the unique challenges and complexities associated
                    with the mining sector, and we offer tailored solutions to
                    help our clients achieve success.
                  </p>
                  <p>
                    Our consultants possess extensive knowledge and experience
                    in the mining industry. They stay updated with the latest
                    trends, regulations, technologies, and best practices. With
                    their deep understanding of the industry landscape, they can
                    provide valuable insights and strategic recommendations to
                    address your specific mining challenges.
                  </p>
                  <p>
                    Mining operations involve intricate processes, machinery,
                    and technologies. Our consultants have a strong technical
                    background and are well-versed in various mining
                    disciplines, including geology, engineering, resource
                    estimation, environmental management, and mine planning.
                    They can analyze your existing systems and practices,
                    identify areas for improvement, and provide innovative
                    solutions to optimize your operations.
                  </p>
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className="row event-item">
                <div className="col-lg-6">
                  <img
                    src="assets/img/events/ReliableTransportationServices.webp"
                    className="d-block w-100"
                    alt="..."
                  />
                </div>
                <div className="col-lg-6 pt-4 pt-lg-0 content">
                  <h3>
                    Reliable Transportation Services: Efficiently Moving Your
                    Mining Goods
                  </h3>
                  <p>
                    Our transportation services are specifically designed to
                    meet the unique needs of the mining industry. We understand
                    the criticality of timely and secure transportation of
                    mining goods, whether it's raw materials, equipment, or
                    finished products. With our reliable transportation
                    services, we ensure that your goods are efficiently moved
                    from point A to point B.
                  </p>
                  <p>
                    We maintain a diverse and well-maintained fleet of vehicles
                    suitable for transporting mining goods of various sizes and
                    weights. Our fleet includes trucks, trailers, flatbeds, and
                    specialized vehicles equipped with features to secure and
                    protect your valuable cargo during transit.
                  </p>
                  <p>
                    Our drivers are highly skilled and experienced in
                    transporting mining goods. They are familiar with the
                    challenges and safety requirements of the mining industry,
                    ensuring the smooth and secure transportation of your goods.
                    Our drivers strictly adhere to traffic regulations, safety
                    protocols, and best practices to mitigate any potential
                    risks
                  </p>
                </div>
              </div>
            </SwiperSlide>
          </Swiper>
        </div>
        {/* <div
          className="testimonials-slider aos-init aos-animate" //swiper-container swiper-container-initialized swiper-container-horizontal swiper-container-pointer-events
          data-aos="fade-up"
          data-aos-delay="100"
        >
        </div> */}
      </div>
    </section>
  );
};

export default Events;
