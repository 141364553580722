import React from "react";

export const WhyUs = () => {
  const dataSet = [
    {
      id: "01",
      title: "Expertise",
      description:
        "Khadhan may specialize in streamlining and optimizing the supply chain for the mining industry, ensuring efficient procurement, transportation, and delivery of mining items.The app may offer real-time tracking of mining items, allowing users to monitor the movement and status of their shipments.",
    },
    {
      id: "02",
      title: "Consulting",
      description:
        "Khadhan`s consultancy service contributes to a substantial part of our business with assistance and goodwill provided by the many years of experience and of the mining industry. Khadhan can assist in the provision of consultants to assist and train mine personnel during crusher installations, as well as after-sales training.",
    },
    {
      id: "03",
      title: "Satisfaction Guaranteed",
      description:
        "The world of technology can be fast-paced and scary. That's why our goal is to provide an experience that is tailored to our customer's needs. No matter the, we pride ourselves on providing professional customer service. We guarantee you will be satisfied with our services.",
    },
  ];

  return (
    <section id="why-us" className="why-us background-color-black">
      <div className="container" data-aos="fade-up">
        <div className="section-title">
          <h2>Why Us</h2>
          <p>Why Choose Us?</p>
        </div>

        <div className="row">
          {dataSet.map((data) => {
            return (
              <div className="col-lg-4 d-flex" key={data.id}>
                <div
                  className="box mt-3"
                  data-aos="zoom-in"
                  data-aos-delay="100"
                >
                  <span>{data.id}</span>
                  <h4>{data.title}</h4>
                  <p>{data.description}</p>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
