import React from "react";

const ContactUs = () => {
  return (
    <div
      style={{ backgroundColor: "#0c0b09" }}
      id="contactus"
      className="pb-5 contact"
    >
      <div className="container pt-5 " data-aos="fade-up">
        <div className="section-title pt-5">
          <h2 className="pt-2">Contact</h2>
          <p>Contact Us</p>
        </div>
      </div>
      <div className="mapouter">
        <div className="gmap_canvas">
          <iframe
            className="gmap_iframe"
            width="100%"
            frameBorder="0"
            scrolling="no"
            marginHeight="0"
            marginWidth="0"
            src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d3630.8785955000653!2d82.15264499999999!3d24.489663000000004!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjTCsDI5JzIyLjgiTiA4MsKwMDknMDkuNSJF!5e0!3m2!1sen!2sin!4v1686309562796!5m2!1sen!2sin"
          ></iframe>
        </div>
      </div>
      <div className="container" data-aos="fade-up">
        <div className="row mt-5">
          <div className="col-lg-4">
            <div className="info">
              <div className="address text-light">
                <i className="bi bi-geo-alt"></i>
                <h4>Location:</h4>
                <p>
                  Plot No. 415, Tilai, Village Dadhiya Tahsil Bahari District
                  Sidhi Sidhi Sidhi MP 486675 IN.
                </p>
              </div>

              <div className="open-hours text-light">
                <i className="bi bi-clock"></i>
                <h4>Open Hours:</h4>
                <p>
                  Monday-Sunday:
                  <br />
                  06:00 AM - 10:00 PM
                </p>
              </div>

              <div className="email text-light">
                <i className="bi bi-envelope"></i>
                <h4>Email:</h4>
                <p>info@khadhan.com</p>
              </div>

              <div className="phone text-light">
                <i className="bi bi-phone"></i>
                <h4>Call:</h4>
                <p>+91 9589167394</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
