import React from "react";

export const Founders = () => {
  const dataSet = [
    {
      name: "Gaurav Singh Baghel",
      designation: "Director",
      instagram: "https://www.instagram.com/gauravmanendrasingh/",
      facebook: " https://www.facebook.com/gauravsingh.baghel.315/",
      linkedIn: "https://www.linkedin.com/in/gaurav-singh-72b74b259/",
      image: "assets/img/founders/gaurav-singh-baghel.jpeg",
    },
    {
      name: "Suraj Singh Baghel",
      designation: "Director",
      instagram: "https://www.instagram.com/imvipulsuraj/",
      facebook:
        "https://www.facebook.com/profile.php?id=100003732468640&mibextid=ZbWKwL",
      image: "assets/img/founders/suraj-singh-baghel.jpeg",
    },
    {
      name: "Abhay Singh Baghel",
      designation: "Director",
      instagram: "https://www.instagram.com/abhay_000415/",
      facebook: "https://www.facebook.com/abhay.baghel.1293/",
      twitter: "https://twitter.com/abhaysi01179030",
      image: "assets/img/founders/abhay-singh-baghel.jpeg",
    },
  ];

  return (
    <section id="chefs" className="chefs background-color-black">
      <div className="container pb-5 mb-3" data-aos="fade-up">
        <div className="section-title" style={{ marginTop: "50px" }}>
          <h2>Founders</h2>
          <p>Founders</p>
        </div>

        <div className="row mb-5">
          {dataSet.map((data) => {
            return (
              <div className="col-lg-3 col-md-6" key={data.name}>
                <div className="member" data-aos="zoom-in" data-aos-delay="300">
                  <div div key={data.name}>
                    <img src={data.image} className="img-fluid" alt="" />
                    <div className="member-info">
                      <div className="member-info-content">
                        <h4>{data.name}</h4>
                        <span>{data.designation}</span>
                      </div>
                      <div className="social">
                        {data.instagram && (
                          <a href={data.instagram} target="_blank">
                            <i className="bi bi-instagram"></i>
                          </a>
                        )}
                        {data.facebook && (
                          <a href={data.facebook} target="_blank">
                            <i className="bi bi-facebook"></i>
                          </a>
                        )}
                        {data.linkedIn && (
                          <a href={data.linkedIn} target="_blank">
                            <i className="bi bi-linkedin"></i>
                          </a>
                        )}
                        {data.twitter && (
                          <a href={data.twitter} target="_blank">
                            <i className="bi bi-twitter"></i>
                          </a>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </section>
  );
};
